.break-word {
  overflow-wrap: break-word;
}

.break-spaces {
  white-space: break-spaces;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
}
