.only-print {
  display: none;
}

@media print {
  @page {
    margin: 1cm 0.5cm;
  }

  .only-print {
    display: initial;
  }

  .print-hidden {
    display: none !important;
  }

  .print-page-break-before {
    page-break-before: always !important;
  }

  .print-no-margin {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }

  .print-100-width {
    width: 100% !important;
  }

  .print-font-weight-normal {
    font-weight: 400 !important;
  }

  .print-text-normal {
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 16px !important;
  }

  .print-page {
    background-color: transparent !important;
  }

  .print-title * {
    color: #222 !important;
    font-size: 16px !important;
  }

  .exam-page-card {
    background-color: transparent !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  .print-exam-answer-box {
    box-shadow: none !important;
    background-color: transparent !important;
    border-top: 1px solid #8d8d8d;
    padding: 10px 0 4px !important;
    margin: 0 !important;
    border-radius: 0 !important;

    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: auto;
  }

  .print-exam-answer-box * {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .print-answerHeader {
    padding-top: 4px !important;
  }

  .print-user-title {
    margin-bottom: 12px;
  }

  .print-tableContainer {
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 4px 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .print-table * {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .print-circle {
    width: 15px !important;
    height: 15px !important;
  }

  .print-cellWithLeftBorder {
    width: unset !important;
  }

  .print-exam-advisory-card {
    background-color: transparent !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    page-break-before: always;
  }
}